
            @import "@/assets/sass/vars.portal.scss";
          






















































.single-details-modal {
    .netprice-step {
        border: 1px solid $gray-600;
        border-radius: 6px;
        margin-bottom: 15px;
        padding: 8px;
        font-size: 13px;
    }
}
