
            @import "@/assets/sass/vars.portal.scss";
          



















































































































.regulation-widget {
    text-align: center;
    border: 1px solid $primary;
    border-radius: 6px;
    padding: 15px;

    &__dates {
        color: $gray-800;
        margin-bottom: 20px;
    }

    &__paid {
        font-size: 20px;
        font-weight: 600;
        color: $primary;
    }

    &__actual {
        font-size: 20px;
        border-top: 2px dashed $gray-400;
        font-weight: 600;
        color: $default;
    }
}
